<template>
  <div class="about prose prose-indigo card bg-white bg-opacity-50 mx-auto">
    <div class="card-body">
      <h1 class="card-title">Questions and Answers</h1>
      <h4>Something went wrong, where's the support address?</h4>
      <p>You can contact <a href="mailto:support@cryptoconvert.co.za">support@cryptoconvert.co.za</a>.</p>
      <h4>I don't have a Lightning wallet, which one should I use?</h4>
      <p>We recommend a good <strong>self-custody</strong> Lightning wallet like <a target="_blank" href="https://muun.com">Muun Wallet</a>. Also see <a target="_blank" href="https://www.opennode.com/blog/best-lightning-network-wallets/">this list of wallets</a> for more information.</p>
      <h4>What is a <strong>"self-custody"</strong> wallet and why do you recommend it?</h4>
      <p><strong>"Self-custody"</strong> means the funds are stored on your device only, and nowhere else. You are responsible for keeping your own money safe. If you lose your phone or it gets formatted, you lose your funds, unless you use the wallet's backup functionality.</p>
      <h4>I don't have any Lightning Bitcoin, where can I get some?</h4>
      <p>In Muun Wallet you can deposit some on-chain Bitcoin to get Lightning funds. Follow us and @ us on <a target="_blank" href="https://twitter.com/CryptoConverted/status/1511583775523852288">our Twitter thread</a> for R30 worth of Bitcoin to test. We'll DM you. For you, for niet!</p>
      <h4>What's so special about Yet Another Crypto Service that sells electricity vouchers?</h4>
      <p>Yes, there are lots of digital voucher services out there that accept Cryptocurrencies, even ones that accept Lightning. But ⚡LightningWatts⚡ uses a special type of Lightning invoice called a "<a target="_blank" href="https://lightningwiki.net/index.php/HODL_Invoice">Hold Invoice</a>".</p>
      <h4>What's so special about a Hold Invoice?</h4>
      <p>Hold Invoices that are paid by the buyer can be either accepted or rejected by the merchant. For example, if ⚡LightningWatts⚡ fails to generate a voucher for you, your funds are returned without the need for a refund transaction. Try it: Buy a R25 voucher for meter number 000000. Your funds wil be returned to you automatically.</p>
      <h4>Why Lightning and not just use Bitcoin/Doge/Eth/etc?</h4>
      <p>Bitcoin and other L1 systems are not well suited for merchant payments. Lightning is much better aligned with the needs of a merchant. <a target="_blank" href="https://www.cryptoconvert.co.za/2021/10/03/introducing-lightning-watts/">This blog post</a> explains it.</p>
      <h4>Does it make sense to buy 10 units of Electricity today if I can buy 20 units tomorrow with the same amount of sats?</h4>
      <p>Depends on whether you want your lights to shine today or tomorrow.</p>
      <h4>How does Bitcoin's volatility affect the transaction?</h4>
      <p>The Lighthing Invoice is only valid for a limited amount of time. We take the risk that the price moves lower in that time.</p>
      <h4>Why should I spend my Sats? Shouldn't I be stacking?</h4>
      <p>You should be stacking, but once you have no fiat left you will obviously need to spend your sats. This app will hopefully one day help you deconvert yourself.</p>
      <h4>Have you ever shared any user information with a 3rd party?</h4>
      <p>No, and we don't plan to. Any changes will be communicated in advance and will not be retroactive.</p>
      <h4>Have you ever shared any user information with the authorities?</h4>
      <p>No, and <a target="_blank" href="https://en.wikipedia.org/wiki/Warrant_canary">I will delete this question and answer if we do.</a></p>
    </div>
  </div>
</template>
